<template>
  <div class="soalExamCBT">
    <h3>Question No. {{ no }}</h3>
    <br />
    <div class="soalCBT">
      <a-row :gutter="16" type="flex">
        <a-col :xs="24" :lg="12" class="kontenSoal">
          <div><span ref="soal" v-html="dataSoal.soal"></span></div>
        </a-col>
        <a-col :lg="1" />
        <a-col style="align-self: center" :xs="24" :lg="11" class="jawabanSoal">
          <template v-if="dataSoal.tipeSoal === 'Multiple Choice'">
            <!-- <a-radio-group v-if="dataSoal.tipeSoal === 'Multiple Choice'" :value="jawaban" @change="changeJawaban"> -->
            <!-- <a-radio class="radioStyle" value="a">
                <span>a. </span>
                <span v-html="pilihanJawaban.a" />
              </a-radio>
              <a-radio class="radioStyle" value="b">
                <span>b. </span>
                <span v-html="pilihanJawaban.b" />
              </a-radio>
              <a-radio class="radioStyle" value="c">
                <span>c. </span>
                <span v-html="pilihanJawaban.c" />
              </a-radio>
              <a-radio class="radioStyle" value="d">
                <span>d. </span>
                <span v-html="pilihanJawaban.d" />
              </a-radio> -->
            <div v-for="pilihan in pilihans" :key="pilihan" class="mb-3">
              <a-row type="flex" v-if="pilihanJawaban[pilihan]">
                <a-col :xs="22" :lg="4">
                  <a-button
                    :type="
                      jawaban && pilihan === jawaban.toUpperCase() && !isTeacher
                        ? 'primary'
                        : 'default'
                    "
                    @click.prevent="changeJawaban(pilihan.toLowerCase())"
                    shape="round"
                    size="large"
                    class="d-inline-block"
                    :disabled="isTeacher"
                    >{{ pilihan }}</a-button
                  >
                </a-col>
                <a-col :xs="22" :lg="20" style="align-self: center">
                  <span ref="jawaban" v-html="pilihanJawaban[pilihan]" />
                </a-col>
              </a-row>
            </div>
            <!-- </a-radio-group> -->
          </template>
          <div v-else class="ckEditorContainer">
            <!-- {{jawaban}} -->
            <ckeditor
              :disabled="isTeacher"
              :editor="editor"
              v-model="jawaban"
              @input="changeJawaban"
              :config="editorConfig"
            ></ckeditor>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { renderMathMl } from '@/helpers/mathjax'
export default {
  props: ['no', 'dataSoal', 'isTeacher'],
  data() {
    return {
      jawaban: this.dataSoal.jawaban,
      pilihans: ['A', 'B', 'C', 'D', 'E'],
    }
  },
  methods: {
    changeJawaban(e) {
      if (this.dataSoal.tipeSoal === 'Multiple Choice') {
        this.jawaban = e
        setTimeout(() => {
          this.$emit('goToNext', {
            jawaban: e,
          })
        }, 400)
      } else {
        // console.log(e)
        this.$emit('goToNext', {
          jawaban: e,
        })
      }
    },
  },
  mounted() {
    this.jawaban = this.dataSoal.jawaban

    let arrayOfRef = []
    const soal = this.$refs.soal
    const jawaban = this.$refs.jawaban
    if (soal || jawaban) {
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }

      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
  computed: {
    pilihanJawaban() {
      if (this.dataSoal.pilihanJawaban) {
        const { pilihanJawaban } = this.dataSoal
        // pilihanJawaban.unshift('')
        // pilihanJawaban.unshift('')
        // pilihanJawaban.push('')
        // console.log(pilihanJawaban[0] !== '' && pilihanJawaban[0])
        const newPilihan = []
        pilihanJawaban.forEach((option) => {
          // console.log(option)
          // if (option && option !== '') {
          //   if (option[0] === '<') {
          //     const replaceTagRegex = /<([^'">]+)(.|)*>((a|b|c|d|e)(\.| \.| ))/gmi
          //     // const regexParagraphImage = /(?<=<([^'">]+)*>(a|b|c|d)(|\.(| )| \.(| )| )<([^'">]+)*>).*/gmi
          //     const regexParagraphImage = /(<([^'">]+)*>(a|b|c|d|e)(|\.(| )| \.(| )| )<([^'">]+)*>)/gmi
          //     // return replaceTagRegex
          //     newPilihan.push(replaceTagRegex.exec(option) ? option.replace(option.match(replaceTagRegex), `<${replaceTagRegex.exec(option)[1]}>`) : option.match(regexParagraphImage) ? option.replace(option.match(regexParagraphImage), '') : option)
          //     // newPilihan.push(option.match(regexParagraphImage) ? option.replace(option.match(regexParagraphImage), '') : replaceTagRegex.exec(option) ? option.replace(option.match(replaceTagRegex), `<${replaceTagRegex.exec(option)[1]}>`) : option)
          //   } else {
          //     option = option.trim()
          //     // const regex = /(?<=^(a|b|c|d)( \.|\.| )).*/gmi
          //     // return option.match(regex) ? option.match(regex)[0] : option
          //     const optionSplit = option.split('').slice(2).join('')
          //     if (option[1] === ' ' | option[1] === '.') {
          //       if (optionSplit[1] === '.' | optionSplit[0] === '.') {
          //         // const regex = /(?<=^( |\.)).*/gmi
          //         const regex = /( |\.)/gmi
          //         // return optionSplit.match(regex)[0]
          //         newPilihan.push(optionSplit.replace(optionSplit.match(regex)[0], ''))
          //       } else {
          //         newPilihan.push(optionSplit)
          //       }
          //     } else {
          //       if (option[0] === '.') {
          //         newPilihan.push(optionSplit)
          //       } else {
          //         newPilihan.push(option)
          //       }
          //     }
          //   }
          //   // console.log(a.replace(replaceTagRegex, `<${replaceTagRegex.exec(a)[1]}>`), replaceTagRegex.exec(a))
          //   // const regex = /(?<=^a( \.|\.)|^b( \.|\.)|^c( \.|\.)|^d( \.|\.)).*|(?<=<p>a\.&nbsp;<\/p>|<p>b\.&nbsp;<\/p>|<p>c\.&nbsp;<\/p>|<p>d\.&nbsp;<\/p>).*/gmi
          //   // console.log(option.match(regex))
          // }
          newPilihan.push(option)
        })
        const data = {}
        for (let i = 0; i < newPilihan.length; i++) {
          const letter = String.fromCharCode(65 + i)
          data[letter] = newPilihan[i]
        }
        return data
      } else {
        const data = {}
        for (let i = 0; i < this.multipleChoiceAmount; i++) {
          const letter = String.fromCharCode(65 + i)
          data[letter] = ''
        }
        return data
      }
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    isOnSEB() {
      return this.$store.state.isOnSEB
    },
    editorConfig() {
      const config = this.$store.state.ckEditor.editorConfig
      // config.link.addTargetToExternalLinks = false
      // console.log(config.link.addTargetToExternalLinks)
      const items = this.isOnSEB
        ? config.toolbar.items.filter(
            (item) => item !== 'imageUpload' && item !== 'link',
          )
        : config.toolbar.items
      return {
        ...config,
        toolbar: {
          items,
        },
        removePlugins: ['Link'],
      }
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    multipleChoiceAmount() {
      return this.institusi?.jumlah_pilihan_ganda || 4
    },
  },
  watch: {
    'dataSoal.jawaban'(newVal, oldVal) {
      // console.log(newVal, 'sjsjsj')
      // if (newVal) {
      this.jawaban = newVal
      // }
    },
  },
}
</script>
<style lang="scss">
.soalExamCBT {
  // .kontenSoal {
  //   figure.image {
  //     max-width: 310px;
  //   }
  // }
  .jawabanSoal {
    .ant-radio-group {
      display: block;
      width: 70%;
      margin-left: auto;
      margin-top: auto;

      .radioStyle {
        display: flex;
        white-space: pre-wrap;
        height: 100%;
        line-height: 30px;
        color: #041930;

        .ant-radio {
          margin-top: 2% !important;
          height: 100%;
        }

        span:nth-child(2) {
          display: flex;

          span {
            display: inline-block;
          }
        }
      }
    }

    .ckEditorContainer {
      .ck-editor__editable {
        min-height: 10rem;
        max-height: 20rem;
      }
    }
  }
}
</style>
